<template>
  <div class="user">
    <el-card class="search">
      <el-form ref="searchForm" :model="searchForm" inline>

        <el-form-item label="用户状态：" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择状态" clearable>
            <el-option label="禁用" value="0" />
            <el-option label="正常" value="1" />
          </el-select>
        </el-form-item>

        <el-form-item label="用户名称" prop="username">
          <el-input v-model.trim="searchForm.name" placeholder="用户名称" class="input" clearable />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" @click="getList">
            搜索
          </el-button>
          <el-button type="primary" size="medium" @click="resetSearchData">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="content">
      <div class="btn-wrap">
        <el-button size="mini" type="danger" @click="handleBatchDel">批量删除</el-button>
      </div>
      <el-table :data="list" element-loading-text="Loading" border fit highlight-current-row
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="用户名称" align="center">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <Avatar :url="scope.row.avatar" />
          </template>
        </el-table-column>
        <el-table-column label="角色" align="center">
          <template slot-scope="scope">
            {{ roleMap[scope.row.role] }}
          </template>
        </el-table-column>
        <el-table-column class-name="status-col" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status | statusFilter">{{
              scope.row.status | statusFilterText
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.admin" size="mini" type="danger"
              @click="handleDelete(scope.row.id, scope.row.name)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination background :page-size.sync="pageInfo.pageSize" :current-page.sync="pageInfo.pageNo"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue"

const searchForm = {
  name: "",
  status: ""
}

export default {
  name: 'UserList',
  components: {
    Avatar
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: 'danger',
        1: 'success',
      }
      return statusMap[status]
    },
    statusFilterText(status) {
      const statusMap = {
        0: '禁用',
        1: '正常',
      }
      return statusMap[status]
    },
  },
  data() {
    return {
      list: null,
      total: 0,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      searchForm: { ...searchForm },
      roleMap: {
        admin: "管理员",
        user: "普通用户"
      },
      multipleSelection: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取用户信息
    getList() {
      this.$request.post("/user/list", { ...this.searchForm, ...this.pageInfo }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    handleBatchDel() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请先选择要删除的用户！")
        return;
      }
      this.$confirm(`是否确认删除这些用户 ？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.post("/user/del", { ids: this.multipleSelection.map(t => t.id) }).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.pageInfo.pageNo = 1;
              this.getList();
            }
          })
        })
        .catch(() => { })
    },
    // 删除用户
    handleDelete(id, name) {
      this.$confirm(`是否删除用户：${name} ？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.post("/user/del", { ids: [id] }).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.getList()
            }
          })
        })
        .catch(() => { })
    },
    handleSizeChange() {
      this.pageInfo.pageNo = 1
      this.getList();
    },
    handleCurrentChange() {
      this.getList()
    },
    // 重置表单
    resetSearchData() {
      this.searchForm = {
        name: '',
        status: '',
      }
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.btn-wrap {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
