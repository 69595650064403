<template>
    <img class="avatar" :src="realUrl" @error="handleError" />
</template>
<script>
import defaultAvatar from "@/assets/img/avatar.png"
import { baseUrl } from '@/utils/config'
export default {
    props: {
        url: String
    },
    data() {
        return {
            defaultAvatar
        }
    },
    computed: {
        realUrl() {
            console.log(this.url, "xxxx")
            if (!this.url) {
                return this.defaultAvatar
            }
            return this.url.startsWith('http') ? this.url : baseUrl + this.url
        }
    },
    methods: {
        handleError(e) {
            e.target.src = this.defaultAvatar
        }
    }
}
</script>
<style lang="scss" scoped>
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
</style>